import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Analytics")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c('vue-apex-charts',{staticClass:"mb-2",attrs:{"options":_vm.chartOptions,"series":_vm.chartData}}),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v("Revenue")]),_c(VSpacer),_c('span',[_vm._v("$845k")]),_c('span',{staticClass:"text--primary font-weight-semibold mx-6"},[_vm._v("82%")]),_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronUp)+" ")])],1),_c('div',{staticClass:"d-flex my-1"},[_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v("Transactions")]),_c(VSpacer),_c('span',[_vm._v("$12.5k")]),_c('span',{staticClass:"text--primary font-weight-semibold mx-6"},[_vm._v("52%")]),_c(VIcon,{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronDown)+" ")])],1),_c('div',{staticClass:"d-flex"},[_c('span',{staticClass:"text--primary font-weight-semibold"},[_vm._v("Total Profile")]),_c(VSpacer),_c('span',[_vm._v("$12.8k")]),_c('span',{staticClass:"text--primary font-weight-semibold mx-6"},[_vm._v("28%")]),_c(VIcon,{attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiChevronUp)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }