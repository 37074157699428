import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',[_vm._v("Total Revenue")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,[_c('vue-apex-charts',{attrs:{"id":"total-revenue-chart","options":_vm.chartOptions,"series":_vm.chartData}}),_c('div',{staticClass:"d-flex justify-space-around mt-2"},[_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"pa-1 success rounded-circle me-2"}),_c('span',{staticClass:"text--primary font-weight-semibold text-base"},[_vm._v("856")])]),_c('span',[_vm._v("New Users")])]),_c(VDivider,{attrs:{"vertical":""}}),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"pa-1 primary rounded-circle me-2"}),_c('span',{staticClass:"text--primary font-weight-semibold text-base"},[_vm._v("345")])]),_c('span',[_vm._v("Returning")])]),_c(VDivider,{attrs:{"vertical":""}}),_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"d-flex align-center justify-center"},[_c('div',{staticClass:"pa-1 secondary rounded-circle me-2"}),_c('span',{staticClass:"text--primary font-weight-semibold text-base"},[_vm._v("258")])]),_c('span',[_vm._v("Referrals")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }