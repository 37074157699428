import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"d-flex flex-column"},[_c(VCardTitle,{staticClass:"align-start"},[_c('div',[_c('p',{staticClass:"mb-2"},[_vm._v(" Weekly Sales ")]),_c('small',{staticClass:"text--secondary text-base"},[_vm._v("Total 85.4k Sales")])]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c(VCardText,{staticClass:"ma-auto"},[_c('vue-apex-charts',{attrs:{"options":_vm.chartOptions,"series":_vm.chartData}}),_c('div',{staticClass:"d-flex justify-space-around flex-wrap"},[_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg primary--text",attrs:{"rounded":"","size":"40"}},[_c(VIcon,{attrs:{"size":"30","color":"primary"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrendingUp)+" ")])],1),_c('div',{staticClass:"ms-4 d-flex flex-column"},[_c('p',{staticClass:"text--primary font-weight-semibold mb-0 text-base"},[_vm._v(" 21.8k ")]),_c('span',[_vm._v("Sales")])])],1),_c('div',{staticClass:"d-flex align-center"},[_c(VAvatar,{staticClass:"v-avatar-light-bg success--text",attrs:{"rounded":"","size":"40"}},[_c(VIcon,{attrs:{"size":"30","color":"success"}},[_vm._v(" "+_vm._s(_vm.icons.mdiCurrencyUsd)+" ")])],1),_c('div',{staticClass:"ms-4 d-flex flex-column"},[_c('p',{staticClass:"text--primary font-weight-semibold mb-0 text-base"},[_vm._v(" 21.8k ")]),_c('span',[_vm._v("Sales")])])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }