import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VCardTitle,{staticClass:"align-start"},[_c('span',{staticClass:"text-sm font-weight-semibold"},[_vm._v("Total Profit")]),_c(VSpacer),_c(VBtn,{staticClass:"mt-n2 me-n3",attrs:{"icon":"","small":""}},[_c(VIcon,{attrs:{"size":"22"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)],1),_c('vue-apex-charts',{attrs:{"id":"total-revenue-chart","height":"250","options":_vm.chartOptions,"series":_vm.chartData}}),_c(VCardText,{staticClass:"text-center mt-12"},[_c('p',{staticClass:"mb-3 text-xs"},[_vm._v(" 18k new sales ")]),_c(VChip,{staticClass:"v-chip-light-bg primary--text font-weight-semibold",attrs:{"small":""}},[_vm._v(" This Year ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }